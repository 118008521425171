<template>
  <q-page padding>
    <q-toolbar class="q-pr-none q-mb-md">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> Editar Afiliado </q-toolbar-title>
    </q-toolbar>
    <div class="q-mb-md">
      <q-breadcrumbs align="left">
        <q-breadcrumbs-el label="Dashboard" to="/" />
        <q-breadcrumbs-el label="Gerenciar" to="/affiliate" />
        <q-breadcrumbs-el label="Editar Afiliado" exact />
      </q-breadcrumbs>
    </div>
    <q-separator spaced />
    <div class="row items-center justify-end q-pb-md">
      <div>
        <q-btn color="primary" icon="mdi-arrow-left" unelevated label="Voltar" no-caps to="/affiliate" />
      </div>
    </div>
    <form-affiliate :id="id" />
  </q-page>
</template>

<script>
import FormAffiliate from '../../components/affiliate/FormAffiliate.vue';
import AffiliateMixin from '../../mixins/AffiliateMixin';
export default {
  components: { FormAffiliate },
  name: 'ManagerAffiliate',
  mixins: [AffiliateMixin],
  props: {
    id: String
  }
};
</script>

<style></style>
