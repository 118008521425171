<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h6">Dados Pessoais</div>
    </q-card-section>
    <q-form @submit="onSave">
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-input outlined label="Nome*" v-model="affiliateData.name" type="text" lazy-rules :rules="[isRequired]" />
        </div>
        <div class="col-6">
          <q-input
            outlined
            label="Sobrenome*"
            v-model="affiliateData.last_name"
            type="text"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-6">
          <q-input
            outlined
            label="Empresa*"
            v-model="affiliateData.business_name"
            type="text"
            hint="Se você não possui uma empresa, introduza o seu nome completo"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-6">
          <q-input
            outlined
            label="E-mail*"
            disabled
            readonly
            v-model="affiliateData.email"
            type="email"
            hint="Este será o e-mail que você usará para ativar e logar na sua conta"
          />
        </div>
        <div class="col-6">
          <q-input
            outlined
            label="Telefone*"
            v-model="affiliateData.phone_number"
            mask="+## ## #####-####"
            unmasked-value
            hint="Ex. +55 21 99999-9999"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
      </q-card-section>
      <q-card-actions vertical align="right">
        <q-btn class="q-px-md" color="positive" no-caps type="submit" unelevated label="Salvar Alterações" />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import AffiliateService from '../../../services/AffiliateService';
import AffiliateMixin from '../../../mixins/AffiliateMixin';

export default {
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  mixins: [AffiliateService, AffiliateMixin],
  name: 'AffiliateContentPersonalData',
  data() {
    return {
      affiliateData: {
        name: null,
        business_name: null,
        last_name: null,
        email: null,
        phone_number: null
      }
    };
  },

  methods: {
    async setupAffiliateData(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(id);
        if (status === 200) {
          this.affiliateData = data;

          if (this.affiliateData.last_name === null) {
            let nameFormat = this.affiliateData.name.split(/\s* \s*/);

            this.affiliateData.name = nameFormat[0];
            this.affiliateData.last_name = nameFormat[1];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onSave() {
      this.onLoading(true);
      try {
        const { data, status } = await this.editAffiliateData(this.id, this.affiliateData);
        if (status === 200) {
          // eslint-disable-next-line no-unused-vars
          let response = data;
          this.successNotify('Afiliado Atualizado com sucesso');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.setupAffiliateData(this.id);
  }
};
</script>
