<template>
  <div>
    <AffiliateFormChangeEmail
      @updateInputLogin="affiliateData.email = $event"
      @closeDialog="changeEmailDialog = false"
      :changeEmailDialog="changeEmailDialog"
      :affiliateId="affiliateData.id"
    />
    <q-card flat>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-4">
          <q-avatar size="96px" font-size="96px" text-color="grey-5">
            <img :src="getGravatar(affiliateData.email)" />
          </q-avatar>
        </div>
        <div class="col-8">
          <div class="text-h6">Dados de acesso</div>
        </div>
      </q-card-section>
    </q-card>
    <q-card flat>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <q-input v-model="affiliateData.email" outlined readonly type="text" label="Login">
            <template v-if="affiliateData.id" v-slot:append>
              <q-btn
                color="primary"
                no-caps
                unelevated
                icon="mdi-pencil-outline"
                @click="() => (changeEmailDialog = true)"
              />
            </template>
          </q-input>
        </div>

        <div class="col-6">
          <q-input v-if="affiliateData.id" outlined readonly type="text" v-model="affiliateData.id" label="ID">
            <template v-slot:append>
              <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.id)">
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar ID
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
          <q-input v-else outlined readonly type="text" v-model="affiliateData.affiliate.id" label="ID">
            <template v-slot:append>
              <q-btn
                color="primary"
                no-caps
                unelevated
                icon="mdi-content-copy"
                @click="copyData(affiliateData.affiliate.id)"
              >
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar ID
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>

        <div class="col-6">
          <q-input v-if="affiliateData.token" outlined readonly type="text" v-model="affiliateData.token" label="Token">
            <template v-slot:append>
              <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar Token
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
          <q-input v-else outlined readonly type="text" v-model="affiliateData.affiliate.token" label="Token">
            <template v-slot:append>
              <q-btn
                color="primary"
                no-caps
                unelevated
                icon="mdi-content-copy"
                @click="copyData(affiliateData.affiliate.token)"
              >
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar Token
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>
      </q-card-section>

      <q-card-actions class="q-mt-md" align="right">
        <q-btn
          color="negative"
          no-caps
          label="Redefinir Senha"
          class="q-px-md"
          unelevated
          @click="passwordDialog = true"
        />
        <!-- <q-btn
                    color="primary"
                    no-caps
                    label="Redefinir Email"
                    class="q-px-md"
                    unelevated
                    @click="emailDialog = !emailDialog"
                /> -->
      </q-card-actions>
    </q-card>

    <q-card flat class="q-mt-lg" v-if="isMainAffiliate()">
      <q-form @submit.prevent="createNewAccess">
        <q-card-section class="row q-col-gutter-md">
          <div class="col-12 text-h6 text-center">Criar novo acesso:</div>
          <div class="col-6">
            <q-input
              outlined
              v-model="newAccess.name"
              ref="newAccessNameInputRef"
              :label="$t('name') + '*'"
              lazy-rules
              :rules="[isRequired]"
            >
            </q-input>
          </div>
          <div class="col-6">
            <q-input
              outlined
              type="email"
              ref="newAccessEmailInputRef"
              v-model="newAccess.email"
              label="Email *"
              lazy-rules
              :rules="[isRequired, isEmail]"
            >
            </q-input>
          </div>
        </q-card-section>
        <q-card-actions class="q-mt-md" align="right">
          <q-btn color="positive" no-caps label="Criar" class="q-px-md" unelevate type="submit" />
        </q-card-actions>
      </q-form>
    </q-card>
    <q-dialog v-model="deleteAccessDialog.show">
      <q-card class="q-pa-lg">
        <div class="text-h5 q-mb-lg">Deseja realmente excluir este acesso?</div>
        <div class="flex justify-around">
          <q-btn color="primary" @click="deleteAccess(deleteAccessDialog.user_id)">Confirmar</q-btn>
          <q-btn color="negative" @click="deleteAccessDialog = { show: false, user_id: null }">Cancelar</q-btn>
        </div>
      </q-card>
    </q-dialog>

    <q-card flat v-if="isMainAffiliate() && affiliateAccess.length > 1">
      <q-card-section>
        <div class="col-12 text-h6 text-center">Acessos Secundários:</div>
        <div class="row col-12" v-for="(user, index) in affiliateAccess" :key="index">
          <q-input
            v-if="index > 0"
            class="col-6 q-pr-md"
            outlined
            v-model="user.name"
            type="text"
            label="Nome *"
            lazy-rules
            :rules="[isRequired]"
            :readonly="!!affiliateAccess[index].id"
          />
          <q-input
            v-if="index > 0"
            class="col-6"
            outlined
            v-model="user.email"
            type="text"
            label="Email *"
            lazy-rules
            :rules="[isRequired, isEmail]"
            :readonly="!!affiliateAccess[index].id"
          >
            <template v-slot:after>
              <q-btn
                class="q-py-sm"
                unelevated
                clearable
                clear-icon="mdi-close"
                color="negative"
                icon="mdi-close"
                @click="deleteAccessDialog = { show: true, user_id: user.id }"
              />
            </template>
          </q-input>
        </div>
      </q-card-section>
    </q-card>

    <q-dialog v-model="passwordDialog" persistent ref="dialogPassword">
      <affiliate-form-change-password
        v-on:close-popup="passwordDialog = false"
        :email="affiliateData.email"
        :id="this.id"
      />
    </q-dialog>
    <AffiliateContentModelUpdateEmail
      @reloadDialog="setupAffiliateData"
      :affiliate-id="id"
      :showDialogEmail="emailDialog"
    />
  </div>
</template>

<script>
import AffiliateService from '../../../services/AffiliateService';
import AffiliateMixin from '../../../mixins/AffiliateMixin';
import AffiliateContentModelUpdateEmail from '@c/affiliate/affiliateContent/AffiliateContentModelUpdateEmail';
import AffiliateFormChangeEmail from './AffiliateFormChangeEmail.vue';
import AffiliateFormChangePassword from './AffiliateFormChangePassword.vue';
import md5 from 'crypto-js/md5';

export default {
  props: {
    id: {
      type: [String, Number],
      require: true
    },
    affiliateData: { type: Object }
  },
  mixins: [AffiliateService, AffiliateMixin],
  components: {
    AffiliateContentModelUpdateEmail,
    AffiliateFormChangeEmail,
    AffiliateFormChangePassword
  },
  name: 'AffiliateContentAccess',
  data() {
    return {
      emailDialog: false,
      changeEmailDialog: false,
      passwordDialog: false,
      newAccess: {},
      affiliateAccess: [],
      deleteAccessDialog: { show: false, user_id: null },
      login: null
    };
  },
  methods: {
    getGravatar(emailAddress) {
      let convertEmailAddress = md5(emailAddress);
      return `https://www.gravatar.com/avatar/${convertEmailAddress}`;
    },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.id);
        if (status === 200) this.affiliateData = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async onSave() {
      this.onLoading(true);
      try {
        this.affiliateData.zip_code = this.affiliateData.zip_code.replace(/\D/g, '');
        const { status } = await this.editAffiliateData(this.id, this.affiliateData);
        if (status === 200) {
          this.passwordDialog = false;
          this.successNotif('Afiliado Atualizado com sucesso');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.$refs.dialogPassword.hide();
        this.onLoading(false);
      }
    },

    async retrieveAccess() {
      try {
        this.onLoading(true);
        const { data, status } = await this.$http.get('/v2/affiliate/settings/users');

        if (status == 200) {
          this.affiliateAccess = data;
        }
      } catch (error) {
        if (error.response.status != 403) {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
        }
      } finally {
        this.onLoading(false);
      }
    },

    isMainAffiliate() {
      return this.affiliateAccess[0] != undefined && this.affiliateData.email == this.affiliateAccess[0].email;
    },

    async createNewAccess() {
      try {
        this.onLoading(true);
        const { data, status } = await this.createNewAffiliateAccess(this.newAccess);
        if (status == 201) {
          this.successNotify(data.message);
          this.retrieveAccess();
          this.newAccess = {};
          this.$refs.newAccessEmailInputRef.resetValidation();
          this.$refs.newAccessNameInputRef.resetValidation();
        }
      } catch (error) {
        if ('email' in error.response.data) {
          this.errorNotify(error.response.data.email[0]);
        } else {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
        }
      } finally {
        this.onLoading(false);
      }
    },

    async deleteAccess(id) {
      try {
        this.onLoading(true);
        const { data, status } = await this.deleteAffiliateAccess(id);
        if (status == 200) {
          this.successNotify(data.message);
          this.retrieveAccess();
          this.deleteAccessDialog = { show: false, user_id: null };
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.retrieveAccess();
  }
};
</script>
