<template>
  <q-page padding>
    <div>
      <q-toolbar class="q-pr-none q-mb-md justify-end">
        <q-btn
          outline
          icon="mdi-filter-outline"
          color="primary"
          no-caps
          class="q-px-sm q-mx-sm"
          label="Filtrar"
          @click="filter = !filter"
        >
          <q-tooltip> {{ $t('filter_report') }}</q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <q-toolbar-title> {{ $t('filter_by') }}</q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <q-input outlined v-model="params.orderId" type="text" :label="$t('request_number')" />
          </div>
          <div class="col-6">
            <q-input outlined v-model="params.actionId" type="text" label="ID da conversão" />
          </div>
          <div class="col-6">
            <q-select
              v-model="params.siteId"
              use-input
              input-debounce="200"
              @filter="filterComboChannels"
              :options="channelsFiltered"
              label="Canal"
              option-label="name"
              option-value="id"
              outlined
              clearable
            />
          </div>
          <div class="col-6">
            <q-select
              v-model="params.campaignId"
              use-input
              input-debounce="200"
              @filter="filterComboCampaigns"
              :options="campaignsFiltered"
              :label="$t('campaign')"
              option-label="campaign_name"
              option-value="campaign_id"
              outlined
              clearable
            />
          </div>
          <div class="col-6">
            <date-input
              outlined
              :label="$t('conversion_date')"
              :v-model="params.date"
              @update="(value) => (params.date = value)"
              clearable
              range
              today-btn
            />
          </div>
          <div class="col-6">
            <div class="row q-col-gutter-sm">
              <div class="col">
                <q-select
                  v-model="params.status"
                  :options="[
                    {
                      label: $t('approved'),
                      value: 2
                    },
                    {
                      label: $t('pending'),
                      value: 1
                    },
                    {
                      label: $t('refused'),
                      value: 0
                    }
                  ]"
                  :label="$t('conversion_status')"
                  outlined
                />
              </div>
            </div>
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterReports" />
          </div>
        </q-card-section>
      </q-card>
    </div>
    <affiliate-list-conversion-reports :idAffiliateOption="id" />
  </q-page>
</template>

<script>
import AffiliateListConversionReports from '@c/reports/affiliate/AffiliateListConversionReports';
import AdvertiserService from '../../../services/AdvertiserService';
import DateInput from '@/widgets/inputsGlobal/DateInput';
import ReportMixin from '@m/ReportMixin';

export default {
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  name: 'AffiliateConversionReportVision',
  components: { AffiliateListConversionReports, DateInput },
  mixins: [ReportMixin, AdvertiserService],
  data() {
    return {
      filter: true,
      params: {
        orderId: null,
        actionId: null,
        siteId: null,
        campaignId: null,
        date: null,
        status: null
      },
      channelsList: [],
      channelsFiltered: [],
      campaignsList: [],
      campaignsFiltered: []
    };
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    }
  },

  created() {
    this.getCampaigns();
    this.getChannels();
  },

  methods: {
    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = this.getCampaignListAffiliate(this.id);
        if (status === 200) {
          this.campaignsList = data;
          this.campaignsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    filterComboCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignsList;
        this.campaignsFiltered = this.campaignsList.filter(
          (v) => v.campaign_name.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          'redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/' + this.id
        );
        if (status === 200) {
          this.channelsList = data;
          this.channelsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    filterComboChannels(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.channelsFiltered = this.channelsList;
        this.channelsFiltered = this.channelsList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },
    clearFilter() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : null;
      this.ActionSetFilterReports([`id_affiliate=${this.id}`].join('%26'));
      this.getConversionReportAffiliate([`id_affiliate=${this.id}`].join('%26'));
    },
    onFilterReports() {
      let filterSelect = [`id_affiliate=${this.id}`];
      if (this.params.siteId) filterSelect.push(`site_id=${this.params.siteId.id}`);
      if (this.params.orderId) filterSelect.push(`order_id=${this.params.orderId}`);
      if (this.params.actionId) filterSelect.push(`transaction_id=${this.params.actionId}`);
      if (this.params.campaignId) filterSelect.push(`campaign_id=${this.params.campaignId.campaign_id}`);

      if (this.params.date) {
        let [from, , to] = this.params.date.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`start_date=${from}`);
        filterSelect.push(`end_date=${to}`);
      }
      if (this.params.status) filterSelect.push(`validation_status=${this.params.status.value}`);
      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getConversionReportAffiliate();
    }
  }
};
</script>

<style scoped></style>
