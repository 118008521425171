<template>
  <q-card flat bordered>
    <q-tabs
      v-model="tab"
      dense
      className="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab name="access" label="Acesso" />
      <q-tab name="personal_data" label="Dados Pessoais" />
      <q-tab name="address" label="Endereço" />
      <q-tab name="pay_info" label="Pagamento" />
      <q-tab name="channels" label="Canais" />
      <q-tab name="approvedCampaign" label="Campanhas Aprovadas"></q-tab>
      <q-tab name="preferences" label="Preferências" />
      <q-tab name="affiliateConversionVision" label="Relatório de Conversão"></q-tab>
      <q-tab name="my_payments" label="Meus Pagamentos" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="access">
        <affiliate-content-access :id="id" :affiliateData="affiliateData" />
      </q-tab-panel>

      <q-tab-panel name="personal_data">
        <AffiliateContentPersonalData :id="id" />
      </q-tab-panel>

      <q-tab-panel name="address">
        <AffiliateContentAddress :id="id" />
      </q-tab-panel>
      <q-tab-panel name="pay_info">
        <affiliateContentPayinfo :id="id" />
      </q-tab-panel>
      <q-tab-panel name="channels">
        <affiliate-content-channels :id="id" />
      </q-tab-panel>
      <q-tab-panel name="preferences">
        <affiliate-content-preferences :id="id" :affiliateData="affiliateData" />
      </q-tab-panel>
      <q-tab-panel name="affiliateConversionVision">
        <affiliate-conversion-report-vision :id="id" />
      </q-tab-panel>
      <q-tab-panel name="my_payments">
        <affiliate-content-my-payments :id="id" />
      </q-tab-panel>
      <q-tab-panel name="approvedCampaign">
        <AffiliateContentApprovedCampaigns :id="id" />
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script>
import AffiliateMixin from '../../mixins/AffiliateMixin';
import AffiliateService from '../../services/AffiliateService';
import AffiliateContentAccess from './affiliateContent/AffiliateContentAccess';
import AffiliateContentPreferences from './affiliateContent/AffiliateContentPreferences';
import AffiliateContentMyPayments from './affiliateContent/AffiliateContentMyPayments';
import AffiliateContentAddress from './affiliateContent/AffiliateContentAddress';
import affiliateContentPayinfo from './affiliateContent/AffiliateContentPayinfo';
import AffiliateContentPersonalData from './affiliateContent/AffiliateContentPersonalData';
import AffiliateContentChannels from './affiliateContent/AffiliateContentChannels';
import AffiliateConversionReportVision from '@c/affiliate/affiliateContent/AffiliateConversionReportVision';
import AffiliateContentApprovedCampaigns from '@c/affiliate/affiliateContent/AffiliateContentApprovedCampaigns';

export default {
  name: 'FormAffiliate',
  mixins: [AffiliateMixin, AffiliateService],
  components: {
    AffiliateContentAccess,
    AffiliateContentAddress,
    affiliateContentPayinfo,
    AffiliateContentPreferences,
    AffiliateContentPersonalData,
    AffiliateContentChannels,
    AffiliateConversionReportVision,
    AffiliateContentMyPayments,
    AffiliateContentApprovedCampaigns
  },
  props: { id: String },
  data() {
    return {
      tab: 'access',
      name: null,
      channels: [],
      affiliateData: null
    };
  },

  computed: {
    columns() {
      return [
        {
          name: 'id',
          required: true,
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: 'Nome',
          field: 'name',
          sortable: true
        },
        {
          align: 'left',
          name: 'url',
          label: 'Url',
          field: 'url'
        },
        {
          align: 'left',
          name: 'category',
          label: 'Categoria',
          field: 'category'
        },
        {
          align: 'left',
          name: 'site_type',
          label: 'Vertical',
          field: 'site_type'
        }
      ];
    }
  },
  methods: {
    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.id);
        if (status === 200) this.affiliateData = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getChannelsByAffiliate(affiliate) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=affiliate&path=%2Fapi%2Fget%2Faffiliate%2Fsite%2Flist%2F' + affiliate
        );
        if (status == 200) this.channels = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.getChannelsByAffiliate(this.id);
    this.setupAffiliateData();
  }
};
</script>

<style></style>
