<template>
  <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
    Total:{{ data.total }}
    <q-space />
    <q-pagination
      unelevated
      v-model="currentPage"
      color="primary"
      :max="data.last_page"
      :max-pages="6"
      boundary-numbers
      direction-links
      ref="paginationAll"
    />
  </q-toolbar>
</template>

<script>
export default {
  name: 'PaginationTable',
  data() {
    return {
      currentPage: 1
    };
  },
  props: {
    gateway: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          sortBy: 'created_at',
          descending: true,
          rowsPerPage: 10
        };
      }
    },
    data: {
      type: Object,
      default: () => {
        return {
          last_page: 5,
          total: 45
        };
      }
    }
  },
  watch: {
    // currentPage() {
    //   this.$emit(
    //     "change",
    //     `page=${this.currentPage}`,
    //     `%26perPage=${this.pagination.rowsPerPage}`
    //   );
    // },
    currentPage() {
      this.$emit(
        'change',
        `${!this.gateway ? '?' : '%26'}page=${this.currentPage}`,
        `%26perPage=${this.pagination.rowsPerPage}`
      );
    }
  }
};
</script>

<style></style>
