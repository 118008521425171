var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{attrs:{"padding":""}},[_c('q-card',{staticClass:"q-mb-sm",attrs:{"flat":"","bordered":""}},[_c('q-toolbar',[_c('q-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('filter_by')))])],1),_c('q-card-section',{staticClass:"q-pt-none row q-col-gutter-md"},[_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"use-input":"","input-debounce":"200","options":_vm.channelsFiltered,"label":"Canal","option-label":"name","option-value":"id","outlined":"","clearable":""},on:{"filter":_vm.filterComboChannels},model:{value:(_vm.params.channelsIdList),callback:function ($$v) {_vm.$set(_vm.params, "channelsIdList", $$v)},expression:"params.channelsIdList"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"use-input":"","input-debounce":"200","options":_vm.campaignsFiltered,"label":_vm.$t('campaign'),"option-label":"campaign_name","option-value":"campaign_id","outlined":"","clearable":""},on:{"filter":_vm.filterComboCampaigns},model:{value:(_vm.params.campaignsIdList),callback:function ($$v) {_vm.$set(_vm.params, "campaignsIdList", $$v)},expression:"params.campaignsIdList"}})],1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col"},[_c('q-select',{attrs:{"options":[
                {
                  label: _vm.$t('pending'),
                  value: 0
                },
                {
                  label: _vm.$t('approved'),
                  value: 1
                },
                {
                  label: 'Pausados',
                  value: 2
                },
                {
                  label: _vm.$t('refused'),
                  value: 3
                }
              ],"label":"Status da Campanha","outlined":""},model:{value:(_vm.params.statusIds),callback:function ($$v) {_vm.$set(_vm.params, "statusIds", $$v)},expression:"params.statusIds"}})],1)])]),_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":_vm.$t('clear'),"no-caps":"","outline":""},on:{"click":_vm.clearFilter}}),_c('q-btn',{staticClass:"q-px-md",attrs:{"color":"positive","label":_vm.$t('search'),"no-caps":"","unelevated":""},on:{"click":_vm.onFilterReports}})],1)])],1),_c('AffiliateContentApprovedCampaignsTable',{on:{"changePage":_vm.getApprovedCampaigns}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }