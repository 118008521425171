import { render, staticRenderFns } from "./AffiliateContentApprovedCampaignsTable.vue?vue&type=template&id=97d61636"
import script from "./AffiliateContentApprovedCampaignsTable.vue?vue&type=script&lang=js"
export * from "./AffiliateContentApprovedCampaignsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QToolbar,QSpace,QPagination});
