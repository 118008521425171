<template>
  <div>
    <q-btn
      class="q-px-md q-mt-md"
      color="positive"
      no-caps
      unelevated
      label="Baixar PDF"
      @click="printPdf()"
      :disabled="!dateSelect"
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="extrato_de_transação"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @hasPaginated="hasPaginated"
    >
      <section slot="pdf-content">
        <q-card flat class="pdf-item">
          <q-card-section class="row">
            <div class="col-3">
              <q-img :src="require('@/assets/images/logo-afilio-transp.png')" style="width: 150px" />
            </div>
            <div class="col-9">
              <q-item>
                <q-item-section>
                  <q-item-label class="text-h3 text-grey">Extrato</q-item-label>
                  <q-item-label class="text-h5 text-grey"> Bank Statement / Extracto de Cuenta </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label
                    >Afiliado: {{ affiliateInfos.name }}
                    {{ affiliateInfos.last_name }}
                  </q-item-label>
                  <q-item-label caption>ID do afiliado: {{ id }}</q-item-label>
                  <q-item-label caption>Período: {{ dateFrom }} a {{ dateTo }} </q-item-label>
                </q-item-section>
              </q-item>
            </div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <q-table flat hide-pagination bordered :data="data" :columns="columns" :pagination="pagination" />
          </q-card-section>
        </q-card>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import AffiliateService from '../../../services/AffiliateService';
import FinanceService from '../../../services/FinanceService';

export default {
  name: 'StatementsInvoice',
  components: { VueHtml2pdf },
  mixins: [AffiliateService, FinanceService],
  props: {
    id: {
      type: [String, Number],
      require: true
    },
    dateSelect: {
      type: [String],
      require: true
    }
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 0
      },
      classPaginate: '',
      columns: [
        {
          name: 'campaign_name',
          required: true,
          label: 'Campanha',
          align: 'left',
          field: 'campaign_name',
          style: 'max-width: 100px; font-size:10px !important'
        },
        {
          name: 'date',
          align: 'left',
          label: 'Mês/Ano Ref.',
          field: 'date',
          style: 'font-size:10px !important'
        },
        {
          name: 'value',
          align: 'left',
          label: 'Valor',
          field: 'value',
          style: 'font-size:10px !important'
        },
        {
          name: 'channel_name',
          align: 'left',
          label: 'Canal de Divulgação',
          field: 'channel_name',
          style: 'font-size:10px !important'
        },
        {
          name: 'description',
          align: 'left',
          label: 'Status',
          field: 'description',
          style: 'font-size:10px !important'
        }
      ],
      data: [],
      affiliatePaymentInfos: {},
      affiliateInfos: {
        name: null,
        last_name: null
      },
      dateFrom: null,
      dateTo: null
    };
  },
  methods: {
    async printPdf() {
      await this.setupAffiliateData();
      await this.populateCommissions();

      this.$refs.html2Pdf.generatePdf();
    },

    hasPaginated() {
      this.classPaginate = 'bg-dark';
    },

    async setupAffiliatePaymentData() {
      try {
        const { data, status } = await this.getAffiliatePaymentInfos(this.id);
        if (status === 200 || status === 201) {
          this.affiliatePaymentInfos = data[0];
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async setupAffiliateData() {
      try {
        const { data, status } = await this.getAffiliateData(this.id);
        if (status === 200 || status === 201) {
          this.affiliateInfos = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async populateCommissions() {
      try {
        let from = this.dateSelect.split(' ')[0];
        let to = this.dateSelect.split(' ')[2];

        this.dateFrom = from;
        this.dateTo = to;

        let dateFrom = this.$moment(this.stringToDate(from, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');

        let dateTo = this.$moment(this.stringToDate(to, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');

        const { data, status } = await this.getStatements(this.id, dateFrom, dateTo);
        if (status === 200 || status === 201) {
          this.data = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
