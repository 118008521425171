<template>
  <div>
    <affiliate-form-add-channel @updateList="getChannelsByAffiliate(id)" :affiliateId="id" />
    <q-table title="Canais" :data="channelsList" row-key="id" flat :columns="columns">
      <template v-slot:top-right>
        <q-btn color="primary" icon="mdi-plus" unelevated no-caps label="Adicionar" @click="onClickAddChannel" />
      </template>
    </q-table>
  </div>
</template>

<script>
import ChannelMixin from '../../../mixins/ChannelMixin';
import AffiliateFormAddChannel from './AffiliateFormAddChannel';

export default {
  name: 'AffiliateContentChannels',
  mixins: [ChannelMixin],
  components: { AffiliateFormAddChannel },
  props: {
    id: String
  },
  data() {
    return {
      channelsList: []
    };
  },
  computed: {
    columns() {
      return [
        {
          name: 'id',
          required: true,
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: 'Nome',
          field: 'name',
          sortable: true
        },
        {
          align: 'left',
          name: 'url',
          label: 'Url',
          field: 'url'
        },
        {
          align: 'left',
          name: 'category',
          label: 'Categoria',
          field: 'category'
        },
        {
          align: 'left',
          name: 'site_type',
          label: 'Vertical',
          field: 'site_type'
        },
        {
          align: 'left',
          name: 'site_status',
          label: 'Status',
          field: 'status'
        }
      ];
    }
  },
  methods: {
    onClickAddChannel() {
      this.ActionSetDialogChannel(true);
    },

    async getChannelsByAffiliate(affiliate) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=affiliate&path=%2Fapi%2Fget%2Faffiliate%2Fsite%2Flist%2F' + affiliate
        );
        if (status == 200) this.channelsList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.getChannelsByAffiliate(this.id);
  }
};
</script>
