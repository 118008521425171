<template>
  <q-dialog v-model="changeEmailDialog" persistent>
    <q-card>
      <q-form @submit="onChangeEmail()">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title> Alterar e-mail de login</q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="() => this.$emit('closeDialog')" />
        </q-toolbar>
        <q-card-section class="row q-col-gutter-md">
          <div class="col-12">
            <q-input
              clearable
              clear-icon="close"
              outlined
              v-model="email"
              type="text"
              label="E-mail"
              :rules="[isRequired, isEmail]"
              lazy-rules
            />
          </div>
          <div class="col-12">
            <q-input
              clearable
              clear-icon="close"
              outlined
              v-model="checkEmail"
              type="text"
              label="Confirmar e-mail"
              :rules="[isRequired, isEmail]"
              lazy-rules
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            no-caps
            class="q-px-md"
            unelevated
            label="Cancelar"
            outline
            color="negative"
            @click="() => this.$emit('closeDialog')"
          />
          <q-btn
            no-caps
            class="q-px-md"
            unelevated
            label="Salvar"
            color="primary"
            type="submit"
            :disabled="disableSaveButton"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import AffiliateService from '@/services/AffiliateService';
export default {
  name: 'AffiliateFormChangeEmail',

  mixins: [AffiliateService],

  props: {
    changeEmailDialog: Boolean,
    affiliateId: Number
  },
  data() {
    return {
      email: null,
      checkEmail: null,
      disableSaveButton: true
    };
  },

  watch: {
    email(newValue) {
      this.enableSaveButton(newValue, this.checkEmail);
    },
    checkEmail(newValue) {
      this.enableSaveButton(this.email, newValue);
    }
  },

  methods: {
    async onChangeEmail() {
      this.onLoading(true);
      try {
        const { status } = await this.editAffiliateAccessEmail(this.affiliateId, this.email);

        if (status == 200) {
          this.successNotify(
            'E-mail alterado com sucesso. Foi enviado um e-mail para redefinição da nova senha de acesso.'
          );
          this.$emit('updateInputLogin', this.email);
          this.$emit('closeDialog');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.$emit('closeDialog');
        this.onLoading(false);
        this.email = null;
        this.checkEmail = null;
      }
    },

    enableSaveButton(email, checkEmail) {
      this.disableSaveButton = !(email && email === checkEmail);
    }
  }
};
</script>
