<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h6">Preferências</div>
    </q-card-section>
    <!-- <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <span class="text-weight-medium q-my-none">Receber informativos</span>
            <q-select
              v-model="contactsDefault"
              :options="contacts"
              label="Informativo"
              outlined
              multiple
              use-chips
              clearable
              clear-icon="mdi-close"
            >
              <template v-slot:selected-item="scope">
                <q-chip
                  removable
                  @remove="scope.removeAtIndex(scope.index)"
                  :tabindex="scope.tabindex"
                  color="primary"
                  text-color="white"
                  class="q-mr-xs"
                >
                  {{ scope.opt.label }}
                </q-chip>
              </template>
            </q-select>
          </div>
          <div class="col-6">
            <span class="text-weight-medium q-my-none">Email*</span>
            <q-input v-model="emailDefault" type="text" readonly outlined >
              <template v-slot:after>
                <q-btn
                  class="q-py-sm"
                  unelevated
                  color="positive"
                  icon="mdi-plus"
                  @click="addContacts"
                />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-separator spaced inset v-if="preferences.length > 0" />  -->
    <q-form @submit="onSave">
      <q-card-section class="q-pt-md" v-if="preferences.length > 0">
        <div class="row q-col-gutter-md q-pb-md" v-for="(item, index) in preferences" :key="index">
          <div class="col-6">
            <q-select
              v-model="item.preferences"
              :options="contacts"
              :label="getLabel(item.preferences)"
              multiple
              clearable
              use-chips
              clear-icon="mdi-close"
              outlined
            >
              <template v-slot:selected-item="scope">
                <q-chip
                  removable
                  @remove="scope.removeAtIndex(scope.index)"
                  :tabindex="scope.tabindex"
                  color="primary"
                  text-color="white"
                  class="q-mr-xs"
                >
                  {{ scope.opt.label }}
                </q-chip>
              </template>
            </q-select>
          </div>
          <div class="col-6">
            <q-input
              v-model="item.email"
              type="text"
              label="Email"
              :readonly="index === 0"
              :rules="[isRequired]"
              outlined
            >
              <template v-slot:after>
                <q-btn
                  v-if="index === 0"
                  class="q-py-sm"
                  unelevated
                  color="positive"
                  icon="mdi-plus"
                  @click="addContacts"
                />
                <q-btn
                  v-else
                  class="q-py-sm"
                  unelevated
                  clearable
                  clear-icon="mdi-close"
                  color="negative"
                  icon="mdi-close"
                  @click="removeContacts(index)"
                />
              </template>
            </q-input>
          </div>
        </div>
      </q-card-section>
      <q-separator spaced />
      <q-card-actions align="left">
        <q-btn unelevated color="positive" type="submit" no-caps padding="sm lg" label="Salvar" />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import AffiliateMixin from '../../../mixins/AffiliateMixin';
import AffiliateService from '../../../services/AffiliateService.js';

export default {
  name: 'AffiliateContentPreferences',
  mixins: [AffiliateMixin, AffiliateService],

  created() {
    this.getAffiliatePreferences(this.id);
    this.emailDefault = this.affiliateData.email;
  },

  props: {
    id: {
      type: [String, Number],
      require: true
    },
    affiliateData: { type: Object }
  },

  data() {
    return {
      contacts: [
        // { value: 'weekly_news', label: 'News Semanal' },
        // {
        //   value: 'new_materials',
        //   label: 'Novos criativos (todos os criativos)'
        // },
        // { value: 'cupom', label: 'Cupons e ofertas' },
        // { value: 'new_campaigns', label: 'Novas campanhas' },
        // {
        //   value: 'commissions_update_all_campaigns',
        //   label: 'Alteração de comissionamento (todas as campanhas)'
        // },
        // {
        //   value: 'commissions_update_affiliate_campaigns',
        //   label: 'Alteração de comissionamento (minhas campanhas)'
        // },
        // { value: 'email_marketing', label: 'Novo e-mail marketing' },
        // { value: 'financial', label: 'Financeiro' }
        { value: 'new_creatives', label: 'Novos Criativos' },
        { value: 'news_general_information', label: 'News (informações gerais)' },
        { value: 'campaign_break', label: 'Pausa de campanha' }
      ],
      contactsDefault: [],
      emailDefault: null,
      preferences: []
    };
  },
  methods: {
    async getAffiliatePreferences(id) {
      this.onLoading(true);
      try {
        if (this.affiliateData.profile == 'afiliado') id = null;
        const { data, status } = await this.getAffiliateOptinEmails(id);
        if (status === 200) {
          this.preferences = this.setPreferences(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    setPreferences(data) {
      const relevantColumns = ['new_creatives', 'news_general_information', 'campaign_break'];

      const contactMap = this.contacts.reduce((map, contact) => {
          map[contact.value] = contact;
          return map;
      }, {});

      data.forEach((el) => {
          el.preferences = relevantColumns
          .filter(key => el[key] === 1)
          .map(key => contactMap[key]);
      });

      return data;
    },

    onSave() {
      console.log(this.preferences);

      let formData = {
          affiliateId: this.id,
          optinEmails: []
      };

      if (this.preferences.length === 0) {
          formData.optinEmails.push({
          email: this.user.email,
          new_creatives: 0,
          news_general_information: 0,
          campaign_break: 0
          });
      } else {
          this.preferences.forEach((item) => {
            let optinEmail = {
                email: item.email,
                new_creatives: 0,
                news_general_information: 0,
                campaign_break: 0
            };

          if (item.preferences) {
              item.preferences.forEach((el) => {
              optinEmail[el.value] = 1;
              });
          }

          formData.optinEmails.push(optinEmail);
          });
        }

    this.updateOptinEmails(formData);
    },

    async updateOptinEmails(formData) {
      this.onLoading(true);
      try {
        const { status } = await this.updateAffiliateOptinEmails(formData);
        if (status === 200) {
          this.successNotify('Preferências atualizadas com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    showDialog() {
      this.$q.dialog({
        title: 'Atenção<em>!</em>',
        message: `<span> Limite de 5 emails atingido!</span>`,
        ok: { color: 'positive' },
        persistent: true,
        html: true
      });
    },
    addContacts() {
      if (this.preferences.length <= 4) this.preferences.push({ contacts: [], email: null });
      else this.showDialog();
    },
    removeContacts(index) {
      this.preferences.splice(index, 1);
    },
    getLabel(preferences) {
      return !preferences || preferences.length === 0 ? 'Não quero receber nenhum informativo' : 'Informativo';
    }
  }
};
</script>
