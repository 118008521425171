<template>
  <q-page padding>
    <q-card flat bordered class="q-mb-sm">
      <q-toolbar>
        <q-toolbar-title> {{ $t('filter_by') }}</q-toolbar-title>
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-select
            v-model="params.channelsIdList"
            use-input
            input-debounce="200"
            @filter="filterComboChannels"
            :options="channelsFiltered"
            label="Canal"
            option-label="name"
            option-value="id"
            outlined
            clearable
          />
        </div>
        <div class="col-6">
          <q-select
            v-model="params.campaignsIdList"
            use-input
            input-debounce="200"
            @filter="filterComboCampaigns"
            :options="campaignsFiltered"
            :label="$t('campaign')"
            option-label="campaign_name"
            option-value="campaign_id"
            outlined
            clearable
          />
        </div>
        <div class="col-6">
          <div class="row q-col-gutter-sm">
            <div class="col">
              <q-select
                v-model="params.statusIds"
                :options="[
                  {
                    label: $t('pending'),
                    value: 0
                  },
                  {
                    label: $t('approved'),
                    value: 1
                  },
                  {
                    label: 'Pausados',
                    value: 2
                  },
                  {
                    label: $t('refused'),
                    value: 3
                  }
                ]"
                label="Status da Campanha"
                outlined
              />
            </div>
          </div>
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterReports" />
        </div>
      </q-card-section>
    </q-card>
    <AffiliateContentApprovedCampaignsTable @changePage="getApprovedCampaigns" />
  </q-page>
</template>

<script>
// import ChannelMixin from "@m/ChannelMixin";
import AdvertiserMixin from '@m/AdvertiserMixin';
import AdvertiserService from '@/services/AdvertiserService';
import AffiliateContentApprovedCampaignsTable from './AffiliateContentApprovedCampaignsTable';

export default {
  name: 'AffiliateContentApprovedCampaigns',
  mixins: [AdvertiserMixin, AdvertiserService],
  components: { AffiliateContentApprovedCampaignsTable },
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      params: {
        channelsIdList: null,
        campaignsIdList: null,
        statusIds: null
      },
      channelsList: [],
      channelsFiltered: [],
      campaignsList: [],
      campaignsFiltered: []
    };
  },
  methods: {
    onFilterReports() {
      let filterSelect = [`id_affiliate=${this.id}`];
      if (this.params.channelsIdList) filterSelect.push(`site_id=${this.params.channelsIdList.id}`);
      if (this.params.campaignsIdList) filterSelect.push(`order_id=${this.params.campaignsIdList}`);
      if (this.params.statusIds) filterSelect.push(`transaction_id=${this.params.statusIds.value}`);

      // this.ActionSetFilterReports(filterSelect.join("%26"));
      this.getApprovedCampaigns();
    },
    async getApprovedCampaigns(page, perPage) {
      if (!this.channelsList) return;

      let query;
      if (!this.filterSelect) query = '';
      else query = `${this.filterSelect}%26`;
      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      if (this.params.channelsIdList) query += `%26channels=${this.params.channelsIdList.id}`;
      else query += `%26channels=${this.channelsList.map((item) => item.id).toString()}`;

      if (this.params.campaignsIdList) query += `%26campaign_id=${this.params.campaignsIdList.campaign_id}`;
      if (this.params.statusIds) query += `%26status=${this.params.statusIds.value}`;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=ADVERTISER&path=/api/v2/campaign/bychannels/approved?${query}`
        );
        if (data && status === 200) {
          this.ActionSetApprovedCampaigns(data.data);
          this.ActionSetPagination(data);
        } else this.ActionSetApprovedCampaigns([]);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    filterComboChannels(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.channelsFiltered = this.channelsList;
        this.channelsFiltered = this.channelsList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },
    filterComboCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignsList;
        this.campaignsFiltered = this.campaignsList.filter(
          (v) => v.campaign_name.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getCampaigns(AffiliateId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getCampaignListAffiliate(AffiliateId);
        if (status === 200) {
          this.campaignsList = data;
          this.campaignsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getChannels(AffiliateId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${AffiliateId}`
        );
        if (status === 200) {
          this.channelsList = data;
          this.channelsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    clearFilter() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : null;
    }
  },
  async created() {
    await this.getChannels(this.id);
    await this.getCampaigns(this.id);
    await this.getApprovedCampaigns();
  }
};
</script>

<style scoped></style>
