<template>
  <div>
    <q-dialog v-model="showModal" persistent ref="dialogPassword">
      <q-card style="width: 700px; max-width: 80vw">
        <q-form @submit="onChangeEmail">
          <q-toolbar class="bg-primary text-white">
            <q-toolbar-title> Alterar senha</q-toolbar-title>
            <q-btn flat round dense icon="mdi-close" @click="showDialogEmail = false" />
          </q-toolbar>
          <q-card-section class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                clearable
                clear-icon="close"
                outlined
                label="Novo email"
                v-model="newEmail"
                lazy-rules
                :rules="[isRequired]"
              >
              </q-input>
            </div>
          </q-card-section>
          <q-card-actions>
            <p class="text-red-5">
              <b>ATENÇÃO</b> Essa alteração irá modificar imediatamente o acesso, envio de emails de notificação,
              redefinição de senha, etc
            </p>
          </q-card-actions>
          <q-card-actions align="right">
            <q-btn no-caps class="q-px-md" unelevated label="Cancelar" outline color="negative" v-close-popup />
            <q-btn no-caps class="q-px-md" unelevated label="Salvar" color="primary" type="submit" />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import AffiliateService from '@/services/AffiliateService';

export default {
  name: 'AffiliateContentModelUpdateEmail',
  mixins: [AffiliateService],
  props: {
    showDialogEmail: {
      require: true
    },
    affiliateId: {
      require: true
    }
  },
  data() {
    return {
      newEmail: null,
      showModal: false
    };
  },
  watch: {
    showDialogEmail() {
      this.showModal = true;
    }
  },
  methods: {
    async onChangeEmail() {
      this.onLoading(true);
      try {
        const { status } = await this.editAffiliateAccessEmail(this.affiliateId, this.newEmail);
        if (status === 200) {
          this.showModal = false;

          this.successNotify('Afiliado Atualizado com sucesso');
          this.$emit('reloadDialog', true);
        }
      } catch (error) {
        let validators = [];

        if (error.response.data.update_affiliate_email !== undefined) {
          Object.keys(error.response.data.update_affiliate_email).forEach(function (key) {
            validators.push(error.response.data.update_affiliate_email[key]);
          });

          this.errorNotify(validators.join('</br>'));
        }
      } finally {
        this.$refs.dialogPassword.hide();
        this.onLoading(false);
      }
    }
  }
};
</script>

<style scoped></style>
