<template>
  <q-dialog v-model="dialogChannel" persistent>
    <q-card style="min-width: 600px">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Adicionar Canal</q-toolbar-title>

        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>

      <q-form @submit="onSave">
        <q-card-section class="row scroll q-col-gutter-md" style="max-height: 70vh">
          <div class="col-9">
            <q-select
              v-model="form.site_type_id"
              use-input
              input-debounce="200"
              @filter="filterSiteType"
              :options="siteTipsFiltered"
              outlined
              label="Tipo do Canal"
              option-label="site_type"
              option-value="id"
              clear-icon="mdi-close"
              clearable
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="col-3">
            <q-input outlined v-model="form.cj_id" type="text" label="CJ ID" maxlength="20" />
          </div>
          <div class="col-12">
            <q-input outlined v-model="form.name" type="text" label="Nome do Site" lazy-rules :rules="[isRequired]" />
          </div>

          <div class="col-12">
            <q-select
              v-model="form.category"
              use-input
              input-debounce="200"
              @filter="filterSiteCategory"
              :options="siteCategoryFiltered"
              multiple
              label="Categoria"
              option-label="name"
              option-value="id"
              outlined
              lazy-rules
              :rules="[isRequired]"
            />
          </div>

          <div class="col-12">
            <q-input v-model="form.url" outlined type="text" label="URL" lazy-rules :rules="[isRequired]" />
          </div>

          <div class="col-12">
            <q-input
              v-model="form.description"
              outlined
              type="textarea"
              label="Descrição"
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />

          <q-btn unelevated label="Adicionar" no-caps padding="sm lg" color="primary" type="submit" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import ChannelMixin from '../../../mixins/ChannelMixin';
import AffiliateService from '../../../services/AffiliateService';

export default {
  name: 'AffiliateFormAddChannel',

  mixins: [ChannelMixin, AffiliateService],

  props: {
    affiliateId: String
  },

  data() {
    return {
      form: {
        site_type_id: null,
        name: null,
        category: [],
        url: null,
        description: null,
        cj_id: null
      },

      siteTips: [],

      siteTipsFiltered: [],

      siteCategory: [],

      siteCategoryFiltered: []
    };
  },

  methods: {
    filterSiteType(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();

        if (!needle) this.siteTipsFiltered = this.siteTips;

        this.siteTipsFiltered = this.siteTips.filter((v) => v.site_type.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterSiteCategory(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();

        if (!needle) this.siteCategoryFiltered = this.siteCategory;

        this.siteCategoryFiltered = this.siteCategory.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    onClose() {
      this.ActionSetDialogChannel(false);
    },

    async getSiteTips() {
      try {
        const { data, status } = await this.getAffiliateSiteTips();

        if (status === 200 || status === 201) {
          this.siteTips = data;

          this.siteTipsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getSitesCategory() {
      try {
        const { data, status } = await this.getAffiliateSiteCategory();

        if (status === 200 || status === 201) {
          this.siteCategory = data;

          this.siteCategoryFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async onSave() {
      this.onLoading(true);

      let data = {
        cj_id: this.form.cj_id,

        force_active: true,

        affiliate_id: this.affiliateId,

        name: this.form.name,

        description: this.form.description,

        url: this.form.url,

        site_type_id: this.form.site_type_id.id,

        category: `${this.form.category.map((item) => item.name)}`
      };

      try {
        const { status } = await this.createAffiliateChannel(data);

        if (status === 200 || status === 201) {
          this.$emit('updateList', this.affiliateId);
          this.successNotify('Canal criado com sucesso');
          this.ActionSetDialogChannel(false);
          this.clearForm();
        }
      } catch (error) {
        if (error.response.data.Error) this.errorNotify(error.response.data.Error);
        else this.errorNotify(error.response.data.message);
      } finally {
        this.onLoading(false);
      }
    },

    clearForm() {
      for (let i in this.form) this.form[i] = Array.isArray(this.form[i]) ? [] : null;
    }
  },

  created() {
    this.getSiteTips();

    this.getSitesCategory();
  }
};
</script>
