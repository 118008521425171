<template>
  <div>
    <!-- {{currentPage}}
        {{reports}} -->
    <q-table
      flat
      bordered
      :data="reports"
      row-key="id"
      :columns="conversionColumns"
      :pagination.sync="pagination"
      :visible-columns="visibleColumns"
    >
      <template v-slot:top-right>
        <q-btn
          outline
          color="grey-3"
          size="12px"
          text-color="grey-8"
          icon-right="archive"
          class="q-mr-sm q-py-xs"
          no-caps
          @click="exportClickReport()"
          unelevated
        >
          <q-tooltip> Exportar como CSV</q-tooltip>
        </q-btn>
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          :display-value="$t('customize_columns')"
          emit-value
          map-options
          :options="conversionColumns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>
      <template v-slot:header-cell-transaction_id="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Id do número do pedido na plataforma V3 </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-order_id="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> número do pedido (passado pelo anunciante) </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <template v-slot:bottom-row v-if="reports.length > 1">
        <q-tr class="text-bold">
          <q-td> TOTAL: </q-td>
          <q-td v-for="i in 6" :key="i" />
          <q-td> {{ parseMoney(reportsTotal[0].transaction_amount) }} </q-td>
          <q-td> {{ parseMoney(reportsTotal[0].affiliate_commission_cost) }} </q-td>
          <q-td v-for="i in 9" :key="i" />
        </q-tr>
      </template>

      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total:{{ reqPagination.total }}
          <q-space />
          Linhas por página:
          <q-select
            v-model="pagination.rowsPerPage"
            :options="[10, 25, 50, 100]"
            options-dense
            menu-shrink
            dense
            borderless
          />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </div>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';

export default {
  props: {
    idAffiliateOption: {
      type: [String, Number],
      require: false
    }
  },
  name: 'ListConversionReports',
  mixins: [ReportMixin],
  data() {
    return {
      visibleColumns: null,
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  computed: {
    conversionColumns() {
      return [
        {
          name: 'campaign_name',
          label: this.$t('campaign_name'),
          align: 'left',
          field: 'campaign_name',
          sortable: false
        },
        {
          name: 'site_id',
          label: 'ID Canal',
          align: 'left',
          field: 'site_id',
          sortable: false
        },
        {
          name: 'site_name',
          label: this.$t('channel_name'),
          align: 'left',
          field: 'site_name',
          sortable: false
        },
        {
          name: 'transaction_id',
          label: this.$t('transaction_id'),
          align: 'left',
          field: 'transaction_id',
          sortable: false
        },
        {
          name: 'order_id',
          label: 'Tra ID',
          align: 'left',
          field: 'order_id',
          sortable: false
        },
        {
          name: 'creative_id',
          label: this.$t('creative_id'),
          align: 'left',
          field: 'creative_id',
          sortable: false
        },
        {
          name: 'comission_type',
          label: this.$t('type'),
          align: 'left',
          field: 'comission_type',
          sortable: false
        },
        {
          name: 'transaction_amount',
          label: this.$t('transaction_amount'),
          align: 'left',
          field: 'transaction_amount',
          sortable: false
        },
        {
          name: 'affiliate_commission_cost',
          label: this.$t('commission'),
          align: 'left',
          field: 'affiliate_commission_cost',
          sortable: false
        },
        {
          name: 'xtrasParams1',
          label: this.$t('affiliate_parameter') + ' Xtra1',
          align: 'left',
          field: 'xtrasParams1',
          sortable: false
        },
        {
          name: 'xtrasParams2',
          label: this.$t('affiliate_parameter') + ' Xtra2',
          align: 'left',
          field: 'xtrasParams2',
          sortable: false
        },
        {
          name: 'xtrasParams3',
          label: this.$t('affiliate_parameter') + ' Xtra3',
          align: 'left',
          field: 'xtrasParams3',
          sortable: false
        },
        {
          name: 'xtrasParams4',
          label: this.$t('affiliate_parameter') + ' Xtra4',
          align: 'left',
          field: 'xtrasParams4',
          sortable: false
        },
        {
          name: 'xtrasParams5',
          label: this.$t('affiliate_parameter') + ' Xtra5',
          align: 'left',
          field: 'xtrasParams5',
          sortable: false
        },
        {
          name: 'bookingDate',
          label: 'Data de Reserva',
          align: 'left',
          field: 'bookingDate',
          sortable: false
        },
        {
          name: 'bookingStatus',
          label: 'Status de Reserva',
          align: 'left',
          field: 'bookingStatus',
          sortable: false
        },
        {
          name: 'couponType',
          label: 'Tipo de Cupom',
          align: 'left',
          field: 'couponType',
          sortable: false
        },
        {
          name: 'couponDiscount',
          label: 'Desconto Cupom',
          align: 'left',
          field: 'couponDiscount',
          sortable: false
        },
        {
          name: 'destCity',
          label: 'Cidade Destino',
          align: 'left',
          field: 'destCity',
          sortable: false
        },
        {
          name: 'destCountry',
          label: 'País Destino',
          align: 'left',
          field: 'destCountry',
          sortable: false
        },
        {
          name: 'endDateTime',
          label: 'Data e horário de término',
          align: 'left',
          field: 'endDateTime',
          sortable: false
        },
        {
          name: 'click_datetime',
          label: this.$t('click_datetime'),
          align: 'left',
          field: 'click_datetime',
          sortable: false
        },
        {
          name: 'transaction_datetime',
          label: this.$t('transaction_datetime'),
          align: 'left',
          field: 'transaction_datetime',
          sortable: false
        },
        {
          name: 'validation_datetime',
          label: this.$t('validation_datetime'),
          align: 'left',
          field: 'validation_datetime',
          sortable: false
        },
        {
          name: 'validation_status',
          label: 'Status',
          align: 'left',
          field: 'validation_status',
          sortable: false,
          format: (status) => this.labelStatus(status)
        }
      ];
    },
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },

    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    }
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getConversionReportAffiliate(`page=${this.currentPage}%26`, `per_page=${this.pagination.rowsPerPage}`);
      }
    },
    'pagination.rowsPerPage'() {
      this.getConversionReportAffiliate(`page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);
      this.ActionSetReports();
    }
  },
  methods: {
    async exportClickReport() {
      console.log(this.filterReports);
      this.onLoading(true);
      let filter;
      if (this.filterReports) filter = this.filterReports.replace('id_affiliate', 'affiliate_id');
      try {
        const { data, status } = await this.$http.get('redirect?app=report&path=/api/get/report/export_csv?' + filter);
        if (status === 200 || status === 201) {
          this.exportTableCsv(data, this.conversionColumns, 'conversion_reports');
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    labelStatus(status) {
      switch (status) {
        case 0:
          return 'Recusado';
        case 1:
          return 'Pendente';
        case 2:
          return 'Aprovado';
        case 3:
          return 'Faturado';
        case 4:
          return 'Pago';
        default:
          return '';
      }
    }
  },
  created() {
    this.ActionSetFilterReports(
      [
        `id_affiliate=${this.idAffiliateOption !== undefined ? this.idAffiliateOption : this.getProfiles.affiliate_id}`
      ].join('%26')
    );
    this.ActionSetReports();
    this.visibleColumns = this.conversionColumns.map((column) => column.name);
    this.getConversionReportAffiliate(null, '%26per_page=' + this.pagination.rowsPerPage);
  }
};
</script>
