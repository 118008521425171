<template>
  <q-table
    title="Campanhas aprovadas"
    :data="approvedCampaigns"
    row-key="Math.random() - id"
    flat
    bordered
    :columns="columnsApprovedCampaigns"
    :pagination="pagination"
  >
    <template v-slot:bottom>
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import AdvertiserMixin from '@m/AdvertiserMixin';

export default {
  mixins: [AdvertiserMixin],

  data() {
    return {
      channelsList: [],
      pagination: {
        sortBy: 'site_id',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.$emit('changePage', `page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);

        // this.getAffiliates(
        // `page=${this.currentPage}`,
        // `%26perPage=${this.pagination.rowsPerPage}`
        // );
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },
    columnsApprovedCampaigns() {
      return [
        {
          name: 'Canal ID',
          required: true,
          label: 'Canal ID',
          align: 'left',
          field: 'site_id',
          sortable: true
        },
        {
          name: 'Canal',
          align: 'left',
          label: 'Canal',
          field: 'site_name',
          sortable: true
        },
        {
          align: 'left',
          name: 'Campanha ID',
          label: 'Campanha ID',
          field: 'id',
          sortable: true
        },
        {
          align: 'left',
          name: 'Campanha',
          label: 'Campanha',
          field: 'name',
          sortable: true
        },
        {
          align: 'left',
          name: 'status',
          label: 'Status',
          field: 'status',
          sortable: true,
          format: (val) => this.formatStatus(val)
        }
      ];
    }
  },
  methods: {
    formatStatus(value) {
      switch (value) {
        case 0:
          return 'Pendente';
        case 1:
          return 'Aprovado';
        case 2:
          return 'Pausado';
        case 3:
          return 'Recusado';
        default:
          return 'Não Definido';
      }
    }
  }
};
</script>

<style></style>
