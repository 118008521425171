import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      columns: [
        {
          name: 'id',
          required: true,
          label: 'Id',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'level',
          align: 'left',
          label: 'Nível',
          field: 'level'
        },
        {
          name: 'business_name',
          align: 'left',
          label: 'Razão Social',
          field: 'business_name',
          sortable: true
        },
        {
          name: 'trademark',
          align: 'left',
          label: 'Nome',
          field: 'trademark',
          sortable: true
        },
        {
          name: 'cnpj',
          align: 'left',
          label: 'CNPJ / Nº de registro',
          field: 'cnpj',
          sortable: true
          // format: (val) => this.maskCnpj(val),
        },
        { name: 'type', align: 'left', label: 'Tipo', field: 'type' },
        {
          name: 'country',
          align: 'left',
          label: 'Nacionalidade',
          field: 'country'
        },
        {
          name: 'created_at',
          align: 'left',
          label: 'Data',
          field: 'created_at'
          // format: (val) => this.$moment(val).format("LL"),
        },
        {
          align: 'left',
          name: 'status',
          label: 'Status',
          field: 'status'
        },
        {
          name: 'action',
          align: 'left',
          label: 'Ações',
          field: 'id'
        }
      ]
    };
  },
  methods: {
    prepareItemCSV(itens) {
      function checkStatus(status) {
        let value;
        switch (status) {
          case 0:
            value = 'Pendente';
            break;

          case 1:
            value = 'Ativo';
            break;

          case 2:
            value = 'Pausado';
            break;

          case 3:
            value = 'Desabilitado';
            break;

          default:
            value = '-';
            break;
        }
        return value;
      }

      return itens.map((item) => {
        return {
          ...item,
          status: checkStatus(item.status)
        };
      });
    },

    prepareColumnCSV(columns) {
      return columns.filter((ele) => ele.name != 'action');
    },

    modeDense(value) {
      this.dense = value;
      console.log(this.dense);
    },
    ...mapActions('Advertiser', [
      'ActionSetAdvertisersAll',
      'ActionSetApprovedCampaigns',
      'ActionSetAdvertisers',
      'ActionSetAdvertiserSelect',
      'ActionSetFilterAdvertiser',
      'ActionSetDialogAdvertiser'
    ]),

    async getAdvertiser() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/advertiser/list');
        if (status === 200) {
          this.ActionSetAdvertisersAll(data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    iconStyle(value) {
      let color = '';
      switch (value) {
        case 1:
          color = '#cd7f32';
          break;
        case 2:
          color = '#c0c0c0';
          break;
        case 3:
          color = '#ffd700';
          break;
        default:
          color = '#BFEFFF ';
      }
      return { color };
    }

    // async checkIfAdvertiserCNPJExists(cnpj) {
    //
    //         const id = null;
    //         const queryString = id ? `cnpj=${cnpj}%26id=${id}` : `cnpj=${cnpj}`;
    //         const { data } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/advertiser/checkcnpj/?' + queryString);
    //         return data.exists ? 'Não será possível cadastrar o CNPJ / Nº de registro' : true;
    //
    // }
  },
  computed: {
    ...mapGetters('Advertiser', [
      'advertisersAll',
      'approvedCampaigns',
      'advertisers',
      'columnsAdvertiser',
      'advertiserSelect',
      'filterAdvertiser',
      'dialogAdvertiser'
    ])
  }
};
