<template>
  <q-dialog v-model="showDetails" persisten @beforeShow="commissions = []" @hide="commissions = []">
    <q-card style="width: 700px; max-width: 80vw">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Detalhes </q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="$emit('close')" />
      </q-toolbar>
      <q-card-section>
        <q-table
          flat
          :data="commissions"
          :columns="columns"
          row-key="name"
          :pagination="{
            rowsPerPage: 15
          }"
        >
          <!-- <template v-slot:bottom>
            <pagination-table
              @change="getAffiliates"
              :data="reqPagination"
              :pagination="paginationCommission"
              :gateway="false"
            />
          </template> -->
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import PaginationTable from '../../../widgets/PaginationTable';
import FinanceService from '../../../services/FinanceService';

export default {
  name: 'CommissionsDetails',
  mixins: [FinanceService, PaginationTable],
  props: {
    id: {
      type: [String, Number],
      default: null,
      require: true
    },
    status: {
      type: [String],
      default: null,
      require: true
    },
    show: {
      type: [Boolean],
      default: false,
      require: true
    }
  },
  data() {
    return {
      paginationCommission: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      },
      showDetails: false,
      commissions: [],
      columns: [
        {
          name: 'campaign_name',
          align: 'left',
          label: 'Nome Campanha',
          field: 'campaign_name',
          sortable: false
        },
        {
          name: 'date',
          align: 'left',
          label: 'Mês/Ano Ref.',
          field: 'date',
          sortable: false
        },
        {
          name: 'value',
          align: 'left',
          label: 'Valor',
          field: 'value',
          sortable: false
        },
        {
          name: 'channel_name',
          align: 'left',
          label: 'Canal de Divulgação',
          field: 'channel_name',
          sortable: false
        },
        {
          name: 'status',
          align: 'left',
          label: 'Status',
          field: 'status',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async setupTable() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateCommissionsByStateNoPaginate(this.id, this.status);
        if (status === 200) this.commissions = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  watch: {
    show() {
      if (this.status !== null && this.id !== null) {
        this.setupTable();
        this.showDetails = !this.showDetails;
      }
    }
  }
};
</script>
