<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h6">Endereço</div>
    </q-card-section>
    <q-form @submit="onSave">
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-4">
          <q-input
            :loading="loadingState"
            bg-color="yellow-2"
            outlined
            mask="#####-###"
            :label="
              !affiliateData.country ||
              affiliateData.country === 'Brasil' ||
              affiliateData.country.nome_pais === 'Brasil'
                ? 'Cep*'
                : 'Zip Code'
            "
            v-model="affiliateData.zip_code"
            type="text"
            lazy-rules
            :rules="[isRequired]"
            :error="errorInput"
            error-message="CEP não encontrado"
            @blur="fillAddress(affiliateData.zip_code)"
          >
            <template v-slot:append>
              <q-btn
                tabindex="-1"
                color="primary"
                no-caps
                unelevated
                icon="mdi-magnify"
                @click="fillAddress(affiliateData.zip_code)"
              />
            </template>
          </q-input>
        </div>
        <div class="col-8">
          <q-input
            :readonly="readOnlyInput"
            outlined
            label="Logradouro*"
            v-model="affiliateData.address"
            type="text"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-2">
          <q-input
            outlined
            label="Numero*"
            v-model="affiliateData.address_number"
            type="text"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-5">
          <q-input outlined label="Complemento" v-model="affiliateData.address_other" type="text" />
        </div>
        <div class="col-5">
          <q-input
            :readonly="readOnlyInput"
            outlined
            label="Bairro*"
            v-model="affiliateData.neighborhood"
            type="text"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-4">
          <q-input
            :readonly="readOnlyInput"
            outlined
            label="Cidade*"
            v-model="affiliateData.city"
            type="text"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-4">
          <q-input
            :readonly="readOnlyInput"
            outlined
            label="Estado*"
            v-model="affiliateData.state"
            type="text"
            lazy-rules
            :rules="[isRequired]"
          />
        </div>
        <div class="col-4">
          <q-select
            outlined
            :options="globalCountryList"
            option-value="nome_pais"
            option-label="nome_pais"
            v-model="affiliateData.country"
            label="País"
            @filter="filterCountry"
            @input="verifyCountry"
            :rules="[(val) => !!val || 'Field is required']"
          />
        </div>
      </q-card-section>
      <q-card-actions vertical align="right">
        <q-btn class="q-px-md" color="positive" type="submit" no-caps unelevated label="Salvar Alterações" />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import AffiliateService from '../../../services/AffiliateService';
import ExtraServices from '../../../services/ExtraServices';
import AffiliateMixin from '../../../mixins/AffiliateMixin';
import { GlobalCountry } from '../../../mixins/GlobalCountry';

export default {
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  mixins: [AffiliateService, AffiliateMixin, GlobalCountry, ExtraServices],
  name: 'AffiliateContentAddress',
  data() {
    return {
      globalCountryList: GlobalCountry,
      readOnlyInput: true,
      errorInput: false,
      loadingState: false,
      affiliateData: {
        address: null,
        address_number: null,
        address_other: null,
        zip_code: null,
        neighborhood: null,
        city: null,
        state: null,
        country: null
      }
    };
  },

  created() {
    this.setupAffiliateData();
  },

  methods: {
    fillAddress(cep) {
      if (!cep) return;
      cep = cep.replace('-', '');
      if (cep.length < 8) return;
      this.loadingState = true;
      this.getAddress(cep)
        .then((response) => {
          !response ? (this.readOnlyInput = false) : '';
          if (!response.erro) {
            this.readOnlyInput = true;
            this.errorInput = false;
            this.affiliateData.address = response.logradouro;
            this.affiliateData.neighborhood = response.bairro;
            this.affiliateData.city = response.localidade;
            this.affiliateData.state = response.uf;
            this.affiliateData.country = 'Brasil';
          } else {
            !this.affiliateData.country ||
            this.affiliateData.country === 'Brasil' ||
            this.affiliateData.country.nome_pais === 'Brasil'
              ? (this.errorInput = true)
              : (this.errorInput = false);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },

    verifyCountry() {
      if (
        !this.affiliateData.country ||
        this.affiliateData.country === 'Brasil' ||
        this.affiliateData.country.nome_pais === 'Brasil'
      ) {
        this.readOnlyInput = true;
      } else {
        this.readOnlyInput = false;
      }
      this.errorInput = false;
    },

    async filterCountry(val, update) {
      if (val === '') {
        update(() => {
          this.globalCountryList = GlobalCountry;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.brazilianBankList = GlobalCountry.filter((v) => v.nome_pais.toLowerCase().indexOf(needle) > -1);
      });
    },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.id);
        if (status === 200) {
          this.affiliateData = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
        this.verifyCountry();
      }
    },

    async onSave() {
      this.onLoading(true);
      try {
        this.affiliateData.zip_code = this.affiliateData.zip_code.replace(/\D/g, '');
        this.affiliateData.country.nome_pais ? (this.affiliateData.country = this.affiliateData.country.nome_pais) : '';

        const { data, status } = await this.editAffiliateData(this.id, this.affiliateData);
        if (status === 200) {
          // eslint-disable-next-line no-unused-vars
          let response = data;
          this.successNotify('Afiliado Atualizado com sucesso');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
