export default {
  methods: {
    async getLinkPreview(url) {
      return await this.$http.get(`/v2/link/preview?url=${url}`);
    },

    async getAddress(cep) {
      try {
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Tempo limite excedido')), 3000)
        );

        const responsePromise = fetch(`https://viacep.com.br/ws/${cep}/json/`);

        const response = await Promise.race([responsePromise, timeoutPromise]);

        if (!response.ok) throw new Error('Erro ao obter dados do CEP');

        const data = await response.json();
        return data;
      } catch (error) {
        return this.getAddressBkp(cep);
      }
    },

    async getAddressBkp(cep) {
      try {
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Tempo limite excedido')), 3000)
        );

        const responsePromise = fetch(`https://opencep.com/v1/${cep}.json`);

        const response = await Promise.race([responsePromise, timeoutPromise]);

        if (!response.ok) throw new Error('Erro ao obter dados do CEP');

        const data = await response.json();
        return data;
      } catch (error) {
        return null;
      }
    }
  }
};
