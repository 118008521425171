<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h6">Meus Pagamentos</div>
    </q-card-section>
    <q-card-section class="q-pt-none row q-col-gutter-md">
      <div class="col-12">
        <list-financial :idAffiliateFromAdmin="id" />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import ListFinancial from '../../../components/affiliate/ListFinancial.vue';

export default {
  name: 'AffiliateContentMyPayments',
  components: { ListFinancial },
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      contacts: [
        { value: 'weekly_news', label: 'News Semanal' },
        {
          value: 'new_materials',
          label: 'Novos criativos (todos os criativos)'
        },
        { value: 'cupom', label: 'Cupons e ofertas' },
        { value: 'new_campaigns', label: 'Cupons e ofertas' },
        {
          value: 'commissions_update_all_campaigns',
          label: 'Alteração de comissionamento (todas as campanhas)'
        },
        {
          value: 'commissions_update_affiliate_campaigns',
          label: 'Alteração de comissionamento (minhas campanhas)'
        },
        { value: 'email_marketing', label: 'Novo e-mail marketing' },
        { value: 'financial', label: 'Financeiro' }
      ],
      contactsDefault: [],
      emailDefault: null,
      preferences: []
    };
  },
  methods: {
    addContacts() {
      this.preferences.push({ contacts: [], email: null });
    },
    removeContacts(index) {
      this.preferences.splice(index, 1);
    }
  }
};
</script>
